import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout"
import SEO from "../components/Seo";
import PostList from "../components/PostList";
import Image from "../components/Image";

const PodcastPage = ({ data }) => {  
  // const { node: posts } = data.allMdx.edges
  let posts = data.allMdx.edges.map((edge) => {
    return edge.node;
  });
  return (
    <Layout>
      <SEO title="The Prior Transformation" />
      <div>
        <Image src="prior_transformation_logo.png" alt="The Prior Transformation Logo"/>
        <h1 className="mt-5">
            The Prior Transformation Podcast
        </h1>
        <p>Welcome to The Prior Transformation, where I ramble for a few minutes about technology or business. Sometimes I also interview smart people.</p>
        <PostList posts={posts} />
      </div>
    </Layout>
  )
}

export default PodcastPage

export const pageQuery = graphql`
  query podcastIndex {
    allMdx(filter: {fields: {contentType: {eq: "podcast"}}}) {
      edges {
        node {
          id
          excerpt
          timeToRead
          tableOfContents
          frontmatter {
            title
            excerpt
            image
            tags
          }
          fields {
            slug
            date
            contentType
          }
        }
      }
    }
  }
`
